<template>
  <v-container class="p-relative pa-0">
    <div class="driver-header">
      <div class="d-flex fill-width justify-space-between align-center indigo white--text pa-4">
        <div class="text-truncate" style="max-width:75px;">
          {{ $store.state.user.name }}
        </div>
        <div class="d-flex align-center" style="max-width: calc(100% - 75px);">
          <v-select
            v-model.number="trxStatus"
            :items="$store.state.trx.driverStatus"
            item-text="name"
            item-value="id"
            dense
            hide-details
            outlined
            dark
            class="rounded-pill"
          />
          <!-- // 0: ready to ship, 1: pickup, 2: send by driver, 3: complete, 99: pending, 100: cancel -->
          <v-btn class="ml-1 ml-sm-2" depressed fab x-small dark color="red" @click.prevent="DO_LOGOUT">
            <v-icon>
              mdi-power
            </v-icon>
          </v-btn>
        </div>
      </div>
      <v-card class="py-4 px-4 white rounded-0">
        <v-select
          v-model.number="trxLimit"
          :items="[{ name: 'Show 12 List', value: 0 }, { name: 'Show 50 List', value: 50 }, { name: 'Show 50 List', value: 100 }, { name: 'Show All Data', value: 1000 }]"
          label="Set Limit"
          item-text="name"
          item-value="value"
          dense
          hide-details
          color="indigo"
        />
      </v-card>
    </div>
    <v-row no-gutters class="driver-list">
      <v-col cols="12" class="px-4 py-3">
        <div v-if="!trx.length" class="d-flex justify-center align-center pa-4">
          - No Data -
        </div>
        <v-card v-for="(t, iTrx) in trx" :key="'trx-' + iTrx" class="d-flex flex-column justify-start align-start flex-sm-row justify-sm-space-between fill-width px-4 py-2 my-2 rounded-lg">
          <div class="d-flex pb-4 pb-sm-0 fill-width justify-space-between">
            <div>
              {{ t.shipping_name }}
              <div v-if="t.shipping_phone" class="caption">
                {{ t.shipping_phone }}
              </div>
              <div v-if="t.shipping_address" class="caption">
                {{ t.shipping_address }}
              </div>
              <div v-if="t.shipping_city" class="caption">
                {{ t.shipping_city }}
              </div>
            </div>
            <div class="pl-12 pl-sm-0 d-flex align-start align-sm-end py-1 pt-sm-0">
              <v-btn
                v-if="t.shipping_phone"
                :href="'tel:' + t.shipping_phone"
                :disabled="parseInt(t.shipping_driver_status) === 2"
                depressed
                dark
                fab
                x-small
                color="blue"
                class="ml-2 white--text"
              >
                <v-icon small>
                  mdi-phone
                </v-icon>
              </v-btn>
              <v-btn
                v-if="t.shipping_phone"
                :disabled="parseInt(t.shipping_driver_status) === 2"
                :href="'https://api.whatsapp.com/send?phone=' + t.shipping_phone + '&text=Hi%20' + encodeURIComponent(t.shipping_name) + '...'"
                target="_blank"
                x-small
                color="green"
                dark
                fab
                depressed
                class="ml-2 white--text"
              >
                <v-icon small>
                  mdi-whatsapp
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex flex-column align-end fill-width">
            <div class="d-flex align-center fill-width pb-2">
              <v-select
                v-model.number="trx[iTrx].shipping_driver_status"
                :disabled="parseInt(t.shipping_driver_status) === 2"
                :items="[
                  { id: 0, name: 'Ready To Ship' },
                  { id: 1, name: 'On Delivery' }
                ].concat(parseInt(t.shipping_driver_status) === 2 ? [{ id: 2, name: 'Completed' }] : [])"
                item-text="name"
                item-value="id"
                label="Set Status"
                outlined
                dense
                hide-details
                class="rounded-pill d-block"
                @change="TrxUpdate({ id: t.id, shipping_driver_status: trx[iTrx].shipping_driver_status })"
              />
              <v-btn v-if="parseInt(t.shipping_driver_status) === 1" :disabled="parseInt(t.shipping_driver_status) === 2" small color="indigo" dark fab depressed class="ml-2 rounded-pill text-capitalize" @click.prevent="OpenDialogCapture(t)">
                <v-icon small>
                  mdi-camera
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <template v-if="dialogScan">
      <ScanBarcode :show="dialogScan" @response="BarcodeResponse" @close="dialogScan = false" />
    </template>
    <v-fab-transition>
      <v-btn
        v-show="true"
        color="blue"
        dark
        fixed
        bottom
        right
        fab
        @click.prevent="dialogScan = !dialogScan"
      >
        <v-icon>mdi-qrcode</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog
      v-model="dialogTrx.show"
      persistent
      max-width="320"
    >
      <v-card v-if="dialogTrx.data">
        <v-card-title class="subtitle-1 font-weight-bold justify-center">
          TRX GMJ/{{ dialogTrx.data.store }}/TRX/{{ dialogTrx.data.id }}
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4">
          <div class="subtitle-1 font-weight-bold">
            {{ dialogTrx.data.shipping_name }}
          </div>
          <div class="body-1">
            {{ dialogTrx.data.shipping_phone }}
          </div>
          <div class="body-1">
            {{ dialogTrx.data.shipping_address }}
          </div>
          <div class="body-1">
            {{ dialogTrx.data.shipping_city }}
          </div>
          <div v-if="dialogTrx.data.shipping_method !== 'driver'" class="red--text py-4 px-10 text-center">
            Metode pengiriman bukan melalui Driver !!!
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            outlined
            small
            dark
            class="px-6 rounded-pill"
            @click="dialogTrx.show = false, dialogTrx.data = null"
          >
            Tutup
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="dialogTrx.data.shipping_method !== 'driver'"
            color="blue darken-1"
            depressed
            small
            dark
            class="px-6 rounded-pill"
            @click="TakeTrxPackage(dialogTrx.data.id)"
          >
            Ambil
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCapture.show"
      persistent
      max-width="320"
    >
      <v-card v-if="dialogCapture.show">
        <v-card-title class="subtitle-1 font-weight-bold justify-center">
          Capture
        </v-card-title>
        <v-divider />
        <v-card-text v-if="dialogCapture.trx" class="pa-0">
          <div v-if="dialogCapture.trx.shipping_driver_image" class="d-flex fill-width pa-0">
            <img :src="dialogCapture.trx.shipping_driver_image" class="d-block" style="max-width: 100%;">
          </div>
          <v-easy-camera v-else-if="dialogCapture.open" v-model="dialogCapture.data" fullscreen @approve="GetImage" @close="dialogCapture.open = false" />
          <div v-if="dialogCapture.trx" class="d-flex fill-width pa-4 align-center justify-center">
            <v-btn depressed small color="indigo" dark class="caption-small text-capitalize font-weight-bold rounded-pill" @click.prevent="dialogCapture.trx.shipping_driver_image = '', dialogCapture.open = true">
              <v-icon class="mr-2" small>
                mdi-camera
              </v-icon>
              {{ dialogCapture.trx.shipping_driver_image ? 'Re-Take Picture' : 'Take Picture' }}
            </v-btn>
          </div>
          <div v-if="dialogCapture.trx.shipping_driver_image" class="pa-4">
            <v-text-field
              v-model="dialogCapture.trx.shipping_driver_notes"
              label="Comment"
              dense
              outlined
              hide-details
              class="block text-center rounded-pill"
            />
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="red darken-1"
            text
            small
            dark
            class="px-4 rounded-pill text-capitalize caption-small"
            @click="dialogCapture.show = false"
          >
            Tutup
          </v-btn>
          <v-spacer />
          <v-spacer />
          <v-btn
            color="blue darken-1"
            depressed
            small
            dark
            class="px-4 rounded-pill text-capitalize caption-small"
            @click="SetDelivered()"
          >
            Set Delivered
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EasyCamera from 'easy-vue-camera'
import { mapState } from 'vuex'
export default {
  components: {
    'v-easy-camera': EasyCamera
  },
  data: () => ({
    trxLimit: 0,
    trx: [],
    dialogScan: false,
    trxStatus: -1,
    dialogTrx: {
      show: false,
      data: null
    },
    dialogCapture: {
      show: false,
      open: false,
      data: null,
      trx: null
    }
  }),
  computed: {
    ...mapState({
      trxData: state => state.trx.trx.data
    })
  },
  watch: {
    trxLimit () {
      this.LoadTrx()
    },
    trxData (d) {
      this.trx = [].concat(d)
    },
    trxStatus () {
      this.LoadTrx()
    },
    'dialogCapture.data' (v) {
      this.dialogCapture.trx.shipping_driver_image = v
      this.dialogCapture.open = false
    }
  },
  mounted () {
    this.trx = this.trxData
    this.GetPosition()
    // this.BarcodeResponse({
    //   status: 'decoded',
    //   data: 'GMJ/1/TRX/40'
    // })
    // this.TakeTrxPackage(64)
  },
  methods: {
    SetDelivered () {
      if (!confirm('Yakin order sudah terkirim ?')) {
        return false
      }
      this.TrxUpdate({
        id: this.dialogCapture.trx.id,
        shipping_driver_status: 2,
        shipping_driver_image: this.dialogCapture.trx.shipping_driver_image,
        shipping_driver_notes: this.dialogCapture.trx.shipping_driver_notes
      })
    },
    OpenDialogCapture (trx) {
      this.dialogCapture.trx = Object.assign(trx)
      this.dialogCapture.open = false
      this.dialogCapture.data = null
      this.dialogCapture.show = true
    },
    GetImage (v) {
      console.log('image: ', v)
    },
    TrxUpdate (data) {
      this.$store.dispatch('trx/TRX_UPDATE', data)
        .then((res) => {
          if (res.status) {
            this.LoadTrx()
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil Update!' })
            this.dialogCapture.open = false
            this.dialogCapture.show = false
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
            if (res.data.response.data.message) {
              this.$store.dispatch('TOAST', { show: true, message: res.data.response.data.message })
            }
          }
        })
    },
    TakeTrxPackage (trxid) {
      if (!confirm('Ambil dan Kirim paket?')) {
        return false
      }
      this.$store.dispatch('trx/TRX_TAKE_DRIVER', trxid)
        .then((res) => {
          if (res.status) {
            this.$store.dispatch('TOAST', { show: true, message: 'Berhasil Diambil!' })
            this.LoadTrx()
            this.dialogTrx.show = false
            this.dialogTrx.data = null
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
            if (res.data.response.data.message) {
              this.$store.dispatch('TOAST', { show: true, message: res.data.response.data.message })
            }
          }
        })
    },
    BarcodeResponse (barcode) {
      if (barcode) {
        if (barcode.status === 'decoded' && barcode.data) {
          const trx = barcode.data.split('/')
          if (trx.length === 2 || trx.length === 4) {
            this.dialogScan = false
            const trxid = trx.length === 2 ? parseInt(trx[1]) : parseInt(trx[3])
            this.$store.dispatch('trx/TRX_GET_ONE', trxid)
              .then((res) => {
                if (res.status) {
                  if (parseInt(res.data.data.id)) {
                    this.dialogTrx.data = Object.assign(res.data.data)
                    this.dialogTrx.show = true
                  } else {
                    this.$store.dispatch('TOAST', { show: true, message: 'Gagal, coba lagi!' })
                  }
                } else {
                  this.$store.dispatch('TOAST', { show: true, message: 'Terjadi Kesalahan' })
                }
              })
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Invalid Barcode!' })
          }
        } else if (barcode.status === 'decoded') {
          this.$store.dispatch('TOAST', { show: true, message: 'Invalid Barcode!' })
        } else {
          // this.$store.dispatch('TOAST', { show: true, message: 'Invalid Barcode!' })
        }
      }
    },
    async GetPosition () {
      const a = await this.$Location(this)
      if (!a.err) {
        this.$store.dispatch('ME_UPDATE', {
          pos_lat: a.data.lat + '',
          pos_lng: a.data.lng + ''
        })
      }
    },
    LoadTrx () {
      this.$store.dispatch('trx/TRX_GET', '?store=' + parseInt(this.$store.state.storeActive) + '&driver=' + this.$store.state.user.id + '&driverStatus=' + this.trxStatus + '&limit=' + this.trxLimit)
        .then((res) => {
          // console.log(res)
        })
    },
    async UpdateTrx (d) {
      if (!confirm('Update Status ?')) {
        return false
      }
      const position = await this.$Location()
      await this.$store.dispatch('trx/TRX_PROCESS', {
        id: d.id,
        shipping_driver_lat: !position.err ? position.lat : d.shipping_driver_lat,
        shipping_driver_lng: !position.err ? position.lng : d.shipping_driver_lng,
        shipping_driver_status: d.shipping_driver_status,
        shipping_driver_notes: d.shipping_driver_notes
      })
        .then((res) => {
          console.log(res)
        })
    },
    DO_LOGOUT () {
      if (!confirm('Logout ?')) {
        return false
      }
      localStorage.removeItem('t')
      this.$store.commit('USER', null)
    }
  }
}
</script>

<style lang="scss">
.driver-header {
  position: absolute;
  top: 0px!important;
  left: 0px!important;
  width: 100%!important;
}
.driver-list {
  position: fixed;
  top: 134px!important;
  width: 100%;
  left: 0px!important;
  height: calc(100vh - 134px)!important;
  overflow: auto;
}
</style>
